import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import toast from 'react-hot-toast'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        let accessToken = this.getToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          accessToken = JSON.parse(accessToken)
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config
        // ** if (status === 401) {
        if (response.status === 401) {
          localStorage.clear()
          toast.error('Unauthorized access!')
          setTimeout(() => {
            window.location.replace('/')
          }, 500)

          //when refresh token API is ready hit it.

          // if (!this.isAlreadyFetchingAccessToken) {
          //   this.setToken(null)
          //   this.isAlreadyFetchingAccessToken = true
          //   this.refreshToken()
          // }
          // const retryOriginalRequest = new Promise((resolve) => {
          //   this.addSubscriber((accessToken) => {
          //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          //     resolve(axios(originalRequest))
          //   })
          // })
          // return retryOriginalRequest
        } else if (response.status === 403) {
          localStorage.clear()
          toast.error('Unauthorized access!')
          setTimeout(() => {
            window.location.replace('/')
          }, 500)
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setUserName(value) {
    localStorage.setItem(this.jwtConfig.setUserName, value)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  loginWithOtp(...args) {
    return axios.post(this.jwtConfig.loginWithOTP, ...args)
  }

  loginWithOtpConfirm(...args) {
    return axios.post(this.jwtConfig.loginWithOTPConfirm, ...args)
  }

  forgotPassword(...args) {
    return axios.post(this.jwtConfig.forgotPassword, ...args)
  }

  forgotPasswordConfirm(...args) {
    return axios.put(this.jwtConfig.forgotPasswordConfirm, ...args)
  }

  onBoard(...args) {
    return axios.post(this.jwtConfig.onBoard, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  async refreshToken() {
    fetch(
      this.jwtConfig.refreshEndpoint +
        `?refreshToken=${this.getRefreshToken()}`,
      {
        method: 'POST'
      }
    )
      .then((r) => r.json())
      .then((t) => {
        this.isAlreadyFetchingAccessToken = false
        if (t.data) {
          // ** Update accessToken in localStorage
          this.setToken(t.data)
          // this.setRefreshToken(r.data.refreshToken)

          this.onAccessTokenFetched(t.data)
        } else {
          localStorage.clear()
          toast.error('Session Expired !!!! Please Login Again')
          setTimeout(() => {
            window.location.replace('/')
          }, 1000)
        }
      })
      .catch((err) => console.log('catch ' + err))

    // return await axios.post(this.jwtConfig.refreshEndpoint + `?refreshToken=${this.getRefreshToken()}`).then((r)=> r).catch(err => console.log(err))
  }
}
export const showSuccessNotification = (message) => {
  return toast.success(message)
}

export const showErrorNotification = (message) => {
  return toast.error(message)
}
