// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import global from './global'

const rootReducer = {
  auth,
  navbar,
  layout,
  global
}

export default rootReducer
