import { createSlice } from "@reduxjs/toolkit"

const initialState={
    loader: false,
}

export const gloablSlice = createSlice({
    name: 'global',
    initialState,
    reducers:{
        setLoader: (state,action) => {
            state.loader = action.payload
        }
    }
})

export const { setLoader } = gloablSlice.actions

export default gloablSlice.reducer